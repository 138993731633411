#ot-sdk-btn.ot-sdk-show-settings.cookieSettings {
  border: inherit;
  height: inherit;
  white-space: inherit;
  padding: inherit;
  line-height: inherit;
  cursor: pointer;
  font-size: 12px;
  background-color: transparent;
}

#ot-sdk-btn.ot-sdk-show-settings.cookieSettings:hover {
  background-color: inherit;
}

#profileFooter>#ot-sdk-btn.ot-sdk-show-settings.cookieSettings:hover {
  background-color: inherit;
}